import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import fetchPlaceTypeIcons from './FetchIcons';



function City() {
  const { city_slug } = useParams();
  const [city, setCity] = useState(null);
  const [places, setPlaces] = useState([]);
  const [activePlace, setActivePlace] = useState(null);
  const [icons, setIcons] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCityDetails = async () => {
      try {
        if (!city_slug) {
          return;
        }

        const response = await fetch(`https://musttrymap.com/mtmphpapi/cities/${city_slug}`);
        if (response.ok) {
          const data = await response.json();
          setCity(data.city);
          setPlaces(data.places);
        } else {
          throw new Error('City details not found');
        }
      } catch (error) {
        console.error('Error retrieving city details:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchIcons = async () => {
      try {
        const iconsData = await fetchPlaceTypeIcons();
        setIcons(iconsData);
      } catch (error) {
        console.error('Error retrieving place type icons:', error);
      }
    };

    fetchCityDetails();
    fetchIcons();
  }, [city_slug]);

  useEffect(() => {
    if (city) {
      document.title = `${city.city_name} - MustTryMap`;
    }
  }, [city]);

  return (
    <>
      <div className="px-4 py-2 my-2 text-center">
        <h1 className="display-5 fw-bold text-body-emphasis">{city && city.city_name}</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            Things you must try in {city && city.city_name}
          </p>
        </div>
      </div>

      { loading && ( <div className="text-center mt-5 pb-4">
            <p className="display-4 fs-4"> Loading data, please wait... </p> </div> ) }

      {!loading && city && Object.keys(icons).length > 0 && (
        <div>
          <MapContainer center={[city.city_latitude, city.city_longitude]} zoom={11} scrollWheelZoom={true}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            {places.map(place => {
              const customIcon = L.divIcon({
                className: 'custom-icon',
                html: `
                  <div class="marker-icon">
                    <img src="/icons/${place.place_type.place_type_icon}" alt="Marker" />
                  </div>
                  <div class="marker-label">${place.label ? place.label : 'Label Not Available'}</div>
                `,
                iconSize: [30, 60],
                iconAnchor: [15, 60],
              });

              return (
                <Marker
                  key={place.place_id}
                  position={[place.place_latitude, place.place_longitude]}
                  icon={customIcon}
                  eventHandlers={{
                    click: () => {
                      if (activePlace && activePlace.place_id === place.place_id) {
                        setActivePlace(null);
                      } else {
                        setActivePlace(place);
                      }
                    }
                  }}
                />
              );
            })}

            {activePlace && (
              <Popup
                position={[
                  activePlace.place_latitude,
                  activePlace.place_longitude
                ]}
                onClose={() => {
                  setActivePlace(null);
                }}
              >
              
              <div className="popUpBox text-center">

              <h5>{activePlace.place_name}</h5>

              <div className="mt-2 text-center">
              <span style={{ fontStyle: 'italic', fontSize: '15px'}}> Must Try </span>
              </div>

              <div className="mt-0 d-flex align-items-center justify-content-center">
                <img
                  src={`popup_icons/${activePlace.reco_source}.png`}
                  alt="Reco Source Icon"
                  style={{ width: '15px', height: '20px' }}
                />
                &nbsp;
                <a href={activePlace.reco_url} target="_blank" rel="noreferrer" style={{ fontStyle: 'bold', fontSize: '17px'}}>
                  {activePlace.recommendation}
                </a>
              </div>

              <div className="mt-2 d-flex align-items-center justify-content-center">
                <img
                  src={`popup_icons/google-maps.png`}
                  alt="Google Maps Icon"
                  style={{ width: '15px', height: '15px' }}
                />
                &nbsp;
                <a href={activePlace.place_google_maps_link}  style={{fontSize: '15px'}} target="_blank" rel="noreferrer">
                  Directions & Reviews
                </a>
              </div>

                {activePlace.note && (
                <div>
                  <div className="mt-2 text-center">
                    <span style={{ fontStyle: 'italic', fontSize: '15px'}}> Note </span>
                  </div>
                  <div className="mt-0 d-flex align-items-center justify-content-center">
                    <span style={{ fontSize: '15px' }}>{activePlace.note}</span>
                  </div>
                </div>
                )}

            </div>


              </Popup>
            )}
          </MapContainer>
        </div>
      )}

      {!loading && (
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-3">
          <Link to="/" className="btn btn-primary btn-lg px-4 gap-3 big-button">
            Back To Homepage
          </Link>
        </div>
      )}
    </>
  );
}

export default City;

