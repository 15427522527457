// FetchIcons.js
const fetchPlaceTypeIcons = async () => {
    try {
      const response = await fetch("https://musttrymap.com/mtmphpapi/place-types");
      if (!response.ok) {
        throw new Error("Error retrieving place type icons");
      }
  
      const data = await response.json();
      return data.reduce((acc, item) => ({ ...acc, [item.place_type_id]: { iconUrl: `icons/${item.place_type_icon}`, iconSize: [30, 30] } }), {});
    } catch (error) {
      console.error(`Error retrieving place type icons: ${error.message}`);
      return {};
    }
  };
  
  export default fetchPlaceTypeIcons;
  