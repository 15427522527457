import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import City from './pages/City';

function App() {


  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="/">
              <h2>MustTryMap</h2>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="https://instagram.com/musttrymap">
                    Instagram
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://twitter.com/musttrymap">
                    Twitter
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://instagram.com/musttrymap">
                    Suggest
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

    <Routes>
    <Route path="/" element={ <Home /> } />
    <Route path="/:city_slug" element={ <City /> } />
    </Routes> 

      <footer>
        <div className="container">
          <p>&copy; {new Date().getFullYear()} MustTryMap. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
