import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Home() {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://musttrymap.com/mtmphpapi/cities')
      .then((response) => response.json())
      .then((data) => setCities(data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <main>
        <div className="px-4 py-3 my-3 text-center">
          <h1 className="display-5 fw-bold text-body-emphasis">Hello</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">Select a city to see the map.</p>
          </div>
        </div>

        {loading && (
          <div className="text-center mt-5 pb-4">
            <p className="display-4 fs-4">Loading data, please wait...</p>
          </div>
        )}

        {!loading && (
          <div className="container">
            <div className="row">
              {cities.map((city) => (
                <div key={city.city_id} className="col-6 col-md-6 col-lg-3">
                  <Link to={`/${city.city_slug}`}>
                    <div className="city-card">
                      <img src={`cities_thumbnails/${city.city_thumbnail}`} alt={city.city_name} />
                      <h4>{city.city_name}</h4>
                      <p>
                        {city.city_country_name} [{city.total_places < 1 ? "Coming soon" : `${city.total_places}`}]
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
    </>
  );
}

export default Home;
